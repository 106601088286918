import React from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import Block from '../components/Block';
import Heading from '../components/Heading';
import Grid from '@mui/material/Grid/Grid';

import utb from '../assets/UTB.png';
import croBrno from '../assets/BrnoCeskyRozhlas.png';
import forbes from '../assets/Forbes.png';
import czechCrunch from '../assets/CzechCrunch.png';
import denik from '../assets/Denik.png';

interface IState {
}

interface IProps extends WithTranslation {
}


const utbLink = {
    cs: 'https://www.utb.cz/aktuality-akce/specialni-folie-eliminuje-pritomnost-viru-a-bakterii-na-dotykovych-obrazovkach/',
    en: 'https://www.utb.cz/en/news-events/special-plastic-sheet-to-eliminate-viruses-and-bacteria-on-touch-screens/'
}

const croBrnoLink = 'https://brno.rozhlas.cz/na-vyvoji-samocistici-folie-spolupracuji-chemici-z-vut-specialni-vrstva-brani-8438148';
const forbesLink = 'https://forbes.cz/viry-a-bakterie-na-displeji-maji-smulu-firma-z-brna-vymyslela-samocistici-folii/';
const czechCrunchLink = 'https://cc.cz/2021/07/dotyk-bez-obav-ceska-antimikrobialni-folie-chce-poskytnout-jistotu-cistoty-pri-pouzivani-dotykovych-displeju/';
const denikLink = 'https://www.denik.cz/pribehy-a-rozhovory/brno-samocistici-folie-podnikani-viry-bakterie-20210503.html';


const utbTitle = {
    cs: 'Univerzita Tomáše Bati ve Zlíně',
    en: 'Tomas Bata University in Zlín'
}
const croBrnoTitle = 'Český rozhlas Brno';
const forbesTitle = 'Forbes';
const czechCrunchTitle = 'Czech Crunch';
const denikTitle = 'Deník';

class Press extends React.Component<IProps, IState, WithTranslation> {

	constructor(props: IProps){		
		super(props);				
		this.state = {};
	}

	render() {	
		const t = this.props.t;
        const ns = 'main';
        const lang = this.props.i18n.language === 'cs' ? 'cs' : 'en';
        const imgWidth = 185;

		return <Block fullHeight={false} rounded color='background.paper' sx={{ m: { xs: '30px', md: '60px'} }}>

            <Heading>
                {t('written-about-us', {ns})}
            </Heading>

            <Grid container sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', '& > *': { display: 'flex', justifyContent: 'center' }}}>
                <Grid item xs>
                    <a target='_blank' rel="noreferrer" href={utbLink[lang]}>
                        <img src={utb} width={imgWidth} alt={utbTitle[lang]} />
                    </a>
                </Grid>
                <Grid item xs>
                    <a target='_blank' rel="noreferrer" href={croBrnoLink}>
                        <img src={croBrno} width={imgWidth} alt={croBrnoTitle} />
                    </a>
                </Grid>
                <Grid item xs>
                    <a target='_blank' rel="noreferrer" href={forbesLink}>
                        <img src={forbes} width={imgWidth} alt={forbesTitle} />
                    </a>
                </Grid>
                <Grid item xs>
                    <a target='_blank' rel="noreferrer" href={czechCrunchLink}>
                        <img src={czechCrunch} width={imgWidth} alt={czechCrunchTitle} />
                    </a>
                </Grid>
                <Grid item xs>
                    <a target='_blank' rel="noreferrer" href={denikLink}>
                        <img src={denik} width={imgWidth} alt={denikTitle} />
                    </a>
                </Grid>
            </Grid>
        </Block>;
	}
}

export default withTranslation()(Press);