import React from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import Block from '../components/Block';
import Heading from '../components/Heading';
import Grid from '@mui/material/Grid/Grid';
import Typography from '@mui/material/Typography';
import { SolidButtonInv } from '../components/Button';

import { ReactComponent as FortesLogo } from '../assets/fortes-interactive-logo.svg';
import { ReactComponent as LotusLogo } from '../assets/lotus-foil-logo.svg';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { Box, Link } from '@mui/material';
import { getMailTo, navigateTo } from '../system/Util';

interface IState {
}

interface IProps extends WithTranslation {
}

class Footer extends React.Component<IProps, IState, WithTranslation> {

	constructor(props: IProps){		
		super(props);				
		this.state = {};
	}

	render() {	
		const t = this.props.t;
        const ns = 'main';

        const white = 'common.white';

		return <Block color='primary.main' rounded fullHeight={false} sx={{ rowGap: '80px', justifyContent: 'space-between', alignItems: 'center', borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}>

            <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: '40px', alignItems: 'center' }}>
                <Heading lightBg={false} showIcon={false} sx={{ mt: { lg: '60px' }, textAlign: 'center' }}>
                    {t('make-service-safer', {ns})}
                </Heading>

                <SolidButtonInv href={getMailTo()}>{t('contact-us', {ns})}</SolidButtonInv>
            </Box>

            <Grid container columnSpacing={1} rowSpacing={4}>
                <Grid item container xs={12} md={8} rowSpacing={4}>
                    <Grid item xs={12}>
                        <LotusLogo style={{ maxWidth: '100%', height: 'unset' }} />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography color={white} variant='body1'>libor@fortesinteractive.com</Typography>
                    </Grid>

                    <Grid item container columnSpacing={4} xs={12}>
                        <Grid item>
                            <Typography color={white} variant='body4'>FORTES interactive, s. r. o.</Typography>
                            <Typography color={white} variant='body4'>Purkyňova 127 Brno 612 00</Typography>
                            <Typography color={white} variant='body4'>Czech Republic</Typography>
                        </Grid>
                        <Grid item>
                            <Typography color={white} variant='body4'>Libor Vošický</Typography>
                            <Typography color={white} variant='body4'>+420 605 013 046</Typography>
                            <Typography color={white} variant='body4'>libor@fortesinteractive.com</Typography>
                        </Grid>
                        <Grid item>
                            <Typography color={white} variant='body4'>IČO: 023 90 248</Typography>
                            <Typography color={white} variant='body4'>DIČ: CZ 023 90 248</Typography>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item container columnSpacing={4} xs={12} md={4}>
                    <Grid item>
                        <Typography color='secondary' variant='body2' sx={{ mb: '32px' }}>LOTUS</Typography>
                        <Link color={white} variant='body4' onClick={() => navigateTo('#about')}>{t("menu.lotus-foil")}</Link>
                        <Link color={white} variant='body4' onClick={() => navigateTo('#benefits')}>{t("menu.benefits")}</Link>
                        <Link color={white} variant='body4' onClick={() => navigateTo('#news')}>{t("menu.news")}</Link>
                        <Link color={white} variant='body4' onClick={() => navigateTo('#references')}>{t("menu.references")}</Link>
                        <Link color={white} variant='body4' onClick={() => navigateTo('#contact')}>{t("menu.contact")}</Link>
                    </Grid>
                    <Grid item>
                        <Typography color='secondary' variant='body2' sx={{ mb: '32px' }}>SOCIAL</Typography>
                        <a target='_blank' rel='noreferrer' href='https://www.facebook.com/FORTESinteractive'>
                            <FacebookIcon sx={{ color: white, fontSize: '48px' }} />
                        </a>
                        <a target='_blank' rel='noreferrer' href='https://www.linkedin.com/company/fortesinteractive/'>
                            <LinkedInIcon sx={{ color: white, fontSize: '48px' }} />
                        </a>
                    </Grid>
                </Grid>
            </Grid>

            <Grid container columnSpacing={4} sx={{ alignItems: 'flex-end', flexGrow: 1 }}>
                <Grid item xs={12} md={8}>
                    <FortesLogo />
                </Grid>
                <Grid item sx={{ opacity: 0.6 }}>
                    <Typography color={white} variant='body4'>{t('all-rights-reserved', {ns})}</Typography>
                </Grid>
                <Grid item sx={{ opacity: 0.6 }}>
                    <Typography color={white} variant='body4'>designed by PEXXA</Typography>
                </Grid>
            </Grid>
        </Block>;
	}
}

export default withTranslation()(Footer);