import React from 'react'
import { Trans, withTranslation, WithTranslation } from 'react-i18next'
import Block from '../components/Block';
import Heading from '../components/Heading';
import Description from '../components/Description';
import Emphasis from '../components/Emphasis';
import Grid from '@mui/material/Grid/Grid';

import touch from '../assets/touch.png';
import { ReactComponent as TouchShapes } from '../assets/touch_shapes.svg';

interface IState {
}

interface IProps extends WithTranslation {
}

class About extends React.Component<IProps, IState, WithTranslation> {

	constructor(props: IProps){		
		super(props);				
		this.state = {};
	}

	render() {	
		const t = this.props.t;
        const ns = 'main';

		return <Block color='background.default'>

            <Grid container spacing={6} className='full-height'>
                <Grid item md={6} className='illustration'>
                    <TouchShapes />
                    <img src={touch} alt='' />
                </Grid>

                <Grid item md={6}>
                    <Heading>
                        {t('what-lotus-is', {ns})}
                    </Heading>

                    <Description>
                        <Trans i18nKey='about-lotus' ns={ns} components={[ <Emphasis /> ]} />
                    </Description>
                </Grid>
            </Grid>
        </Block>;
	}
}

export default withTranslation()(About);