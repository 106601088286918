import React from 'react'
import { styled } from '@mui/material/styles'

import {ReactComponent as Bullet} from '../assets/bullet.svg';
import { List as MuiList, ListItem, ListItemIcon, ListProps, ListItemText } from '@mui/material';

interface IState {
}

interface IProps {
    items: string[],
    lightBg: boolean,
    listProps?: ListProps,
}

class List extends React.Component<IProps, IState> {

    static defaultProps: Partial<IProps> = { lightBg: true };

	constructor(props: IProps){		
		super(props);				
		this.state = {};
	}

	render() {
        const fgColor = this.props.lightBg ? 'primary.main' : 'primary.contrastText';

        const BulletIcon = styled(Bullet)(({ theme }) => ({ fill:
            this.props.lightBg ? theme.palette.primary.main : theme.palette.primary.contrastText,
            marginRight: '16px',
        }));

		return <MuiList dense {...this.props.listProps}>
                {this.props.items.map((item, index) =>
                    
                    <ListItem disablePadding key={index}>
                        <ListItemIcon>
                            <BulletIcon />
                        </ListItemIcon>
                        <ListItemText sx={{ color: fgColor }} primary={item} />
                    </ListItem>    
                )}
            </MuiList>;
	}
}

export default List;