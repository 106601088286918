import { styled } from '@mui/material/styles';
import Button, { ButtonProps } from '@mui/material/Button';


const BaseButton = styled(Button)({
    boxShadow: 'none',
    textTransform: 'unset',
    fontSize: 16,
    padding: '8px 30px',
    border: '1px solid',
    lineHeight: '24px',
    borderRadius: '21px',
    fontFamily: [
      'Poppins',
      'sans-serif',
    ].join(','),
    fontWeight: 400,
    textOverflow: 'ellipsis',
});
  
const SolidButton = styled(BaseButton)<ButtonProps>(({ theme }) => ({
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,

    '&:hover': {
        backgroundColor: theme.palette.primary.contrastText,
        color: theme.palette.primary.main,
        boxShadow: 'none',
    },
    '&:active': {
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.main,
        boxShadow: 'none',
    },
}));

const SolidButtonInv = styled(BaseButton)<ButtonProps>(({ theme }) => ({
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.contrastText,
    borderColor: theme.palette.primary.contrastText,

    '&:hover': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        boxShadow: 'none',
    },
    '&:active': {
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.primary.contrastText,
        boxShadow: 'none',
    },
}));

const TextButton = styled(BaseButton)<ButtonProps>(({ theme }) => ({
    color: theme.palette.primary.main,
    backgroundColor: 'transparent',
    borderColor: 'transparent',

    '&:hover': {
        color: theme.palette.primary.main,
        backgroundColor: 'transparent',
        borderColor: theme.palette.primary.main,
        boxShadow: 'none',
    },
    '&:active': {
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
        boxShadow: 'none',
    },
}));

const TextButtonInv = styled(BaseButton)<ButtonProps>(({ theme }) => ({
    color: theme.palette.primary.contrastText,
    backgroundColor: 'transparent',
    borderColor: 'transparent',

    '&:hover': {
        color: theme.palette.primary.contrastText,
        backgroundColor: 'transparent',
        borderColor: theme.palette.primary.contrastText,
        boxShadow: 'none',
    },
    '&:active': {
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.primary.contrastText,
        borderColor: theme.palette.primary.contrastText,
        boxShadow: 'none',
    },
}));

const OutlineButton = styled(BaseButton)<ButtonProps>(({ theme }) => ({
    color: theme.palette.primary.main,
    backgroundColor: 'transparent',
    borderColor: theme.palette.primary.main,

    '&:hover': {
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.main,
        boxShadow: 'none',
    },
    '&:active': {
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.main,
        boxShadow: 'none',
    },
}));

const OutlineButtonInv = styled(BaseButton)<ButtonProps>(({ theme }) => ({
    color: theme.palette.primary.contrastText,
    backgroundColor: 'transparent',
    borderColor: theme.palette.primary.contrastText,

    '&:hover': {
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.primary.contrastText,
        boxShadow: 'none',
    },
    '&:active': {
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.primary.contrastText,
        boxShadow: 'none',
    },
}));


export { BaseButton, SolidButton, TextButton, OutlineButton, SolidButtonInv, TextButtonInv, OutlineButtonInv };