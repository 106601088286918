import React from 'react'
import { Trans, withTranslation, WithTranslation } from 'react-i18next'
import Block from '../components/Block';
import Heading from '../components/Heading';
import Description from '../components/Description';
import Emphasis from '../components/Emphasis';
import Grid from '@mui/material/Grid/Grid';
import List from '../components/List';

import selfService from '../assets/self-service.png';
import { ReactComponent as SelfServiceShapes } from '../assets/self-service_shapes.svg';

interface IState {
}

interface IProps extends WithTranslation {
}

class Usage extends React.Component<IProps, IState, WithTranslation> {

	constructor(props: IProps){		
		super(props);				
		this.state = {};
	}

	render() {	
		const t = this.props.t;
        const ns = 'main';

		return <Block rounded color='background.default'>

            <Grid container spacing={6} className='full-height'>
                <Grid item md={5} className='illustration'>
                    <SelfServiceShapes />
                    <img src={selfService} alt=''/>
                </Grid>

                <Grid item md={7}>
                    <Heading>
                        {t('where-lotus-can-be-used', {ns})}
                    </Heading>

                    <Description>
                        <Trans i18nKey='lotus-usage' ns={ns} components={[ <Emphasis /> ]} />
                    </Description>

                    <List items={t('use-cases', {ns, returnObjects: true}) ?? []} />
                </Grid>
            </Grid>
        </Block>;
	}
}

export default withTranslation()(Usage);