import React from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import Block from '../components/Block';
import Heading from '../components/Heading';

import { Box, Grid } from '@mui/material';
import { SolidButton } from '../components/Button';
import Card, { ICardItem } from '../components/Card';

import { ReactComponent as EnvironmentIcon } from '../assets/benefits/environment.svg';
import { ReactComponent as AllDayIcon } from '../assets/benefits/24h.svg';
import { ReactComponent as CovidIcon } from '../assets/benefits/covid.svg';
import { ReactComponent as CzIcon } from '../assets/benefits/cz.svg';
import { ReactComponent as IdeaIcon } from '../assets/benefits/idea.svg';
import { ReactComponent as ImageIcon } from '../assets/benefits/image.svg';
import { ReactComponent as PanelIcon } from '../assets/benefits/panel.svg';
import { navigateTo } from '../system/Util';


const icons = [
    <EnvironmentIcon />,
    <AllDayIcon />,
    <PanelIcon />,
    <CovidIcon />,
    <ImageIcon />,
    <CzIcon />,
    <IdeaIcon />,
]

interface IState {
}

interface IProps extends WithTranslation {
}

class Benefits extends React.Component<IProps, IState, WithTranslation> {

	constructor(props: IProps){		
		super(props);				
		this.state = {};
	}

	render() {	
		const t = this.props.t;
        const ns = 'main';
        const cards = t<string, Partial<ICardItem>[]>('benefits', { ns, returnObjects: true },) ?? [];

		return <Block color='background.paper' rounded sx={{ position: 'relative', maxWidth: '100vw' }}>

            <Grid container spacing={6} sx={{ boxSizing: 'border-box', height: '100%', overflow: 'hidden' }}>
                <Grid item xs={12} sm={12} md={5}>
                    <Heading>
                        {t('what-benefits-lotus-has', {ns})}
                    </Heading>
                    <SolidButton onClick={() => navigateTo('#function')} sx={{ mt: '90px' }}>{t('what-lotus-foil-does', {ns})}</SolidButton>
                </Grid>

                <Grid item xs={12} sm={12} md={7}>
                    <Box sx={{ height: '100%', display: 'flex', alignItems: 'center', overflowX: 'auto', columnGap: '50px' }}>
                        {cards?.map((item, index) =>
                            <Card key={index} sx={{ flexShrink: 0 }} item={{
                                title: item.title ?? '',
                                description: item.description ?? '',
                                icon: icons[index % icons.length],
                                tag: (index + 1).toString().padStart(2, '0') }} />
                                )}
                    </Box>
                </Grid>

                {/* <Box sx={{ width: '100%', height: 'calc(100% + 120px)', position: 'absolute', top: '-60px', left: 0, zIndex: -1, bgcolor: 'background.paper' }} /> */}
            </Grid>
        </Block>;
	}
}

export default withTranslation()(Benefits);