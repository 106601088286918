import React from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { BrowserRouter } from 'react-router-dom';

// Import GUI:
import { styled } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import Divider from '@mui/material/Divider';

import CssBaseline from '@mui/material/CssBaseline';
import { AppBar, Toolbar, Box, MenuItem, Drawer, AppBarProps as MuiAppBarProps, Container} from '@mui/material';
import { CustomThemeContext }  from './CustomThemeProvider';

import { OutlineButton, OutlineButtonInv, SolidButton, TextButton, TextButtonInv } from '../components/Button';
import Intro from '../blocks/Intro';
import Motto from '../blocks/Motto';
import About from '../blocks/About';
import Function from '../blocks/Function';
import Usage from '../blocks/Usage';
import FAQ from '../blocks/FAQ';
import Testers from '../blocks/Testers';
import Press from '../blocks/Press';
import Contacts from '../blocks/Contacts';
import Footer from '../blocks/Footer';

import { ReactComponent as LotusLogo } from '../assets/lotus-foil-logo.svg';
import { ReactComponent as LotusLogoLight } from '../assets/lotus-foil-logo_light.svg';
import Benefits from '../blocks/Benefits';
import { navigateTo } from './Util';


interface IState {
	anchorElement : any
	mobileMoreAnchorElement : any
	languageAnchorElement : any
	sidebarOpened : boolean
	currentLanguage : string
}

interface IProps extends WithTranslation {
	modules: any[],
	setLanguage : (x: string) => void,
	currentLanguage : string,
	children?: React.ReactNode,	
}

interface AppBarProps extends MuiAppBarProps {
	open?: boolean;	
}

const drawerWidth = 240;
		

const StyledAppBar = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
	easing: theme.transitions.easing.sharp,
	duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
	marginLeft: drawerWidth,
	width: `calc(100% - ${drawerWidth}px)`,
	transition: theme.transitions.create(['width', 'margin'], {
	  easing: theme.transitions.easing.sharp,
	  duration: theme.transitions.duration.enteringScreen,
	}),
  }),
}));


class Base extends React.Component<IProps, IState, WithTranslation> {

	constructor(props: IProps){		
		super(props);				
		this.state = {
			anchorElement : null,
			mobileMoreAnchorElement : null,
			languageAnchorElement : null,
			sidebarOpened : false,
			currentLanguage : this.props.currentLanguage || "en"
		};
		this.handleMenuClose = this.handleMenuClose.bind(this);
		this.handleMenuOpen = this.handleMenuOpen.bind(this);
		this.handleMobileMenuOpen = this.handleMobileMenuOpen.bind(this);
		this.handleMobileMenuClose = this.handleMobileMenuClose.bind(this);
	}

	handleMenuClose(e : any, after : any = null) {
		if (typeof(after) === "function")
			this.setState({anchorElement : null, languageAnchorElement : null}, after);
		else
			this.setState({anchorElement : null, languageAnchorElement : null});
		this.handleMobileMenuClose(e);
	};

	handleMenuOpen(e : any) {
		this.setState({anchorElement : e.currentTarget});
	};

	handleMobileMenuOpen(e: any) {
		this.setState({mobileMoreAnchorElement : e.currentTarget});
	}

	handleMobileMenuClose(e : any) {
		this.setState({mobileMoreAnchorElement : null});
	};
	
	handleLanguageChange(e: any, lang: string)
	{
		this.props.setLanguage(lang);
		this.setState({ currentLanguage: lang });
		this.handleMenuClose(e);
	}
	
	render() {	
		const t = this.props.t;
		
		const mobileMenuId = 'primary-menu-mobile';
		const langButtonStyle = { textTransform: 'uppercase', fontSize: '12px', pl: 0, pr: 0 };
		
		const container = window !== undefined ? () => window.document.body : undefined;

		// ========== ========== ==========
		return(<CustomThemeContext.Consumer>{ theme => (
		<BrowserRouter><Box sx={{ display: 'flex' }}>		
		<CssBaseline />
		
		{/* { <Navigate to={this.state.navigateFromSearch} /> } */}
		
		<StyledAppBar position="absolute" open={this.state.sidebarOpened} color='transparent' sx={{ p: { xs: '8px 30px', md: '16px 60px'} }}>
			<Container maxWidth='xl'>

			{!Boolean(this.state.mobileMoreAnchorElement) && <Toolbar disableGutters>
					<LotusLogo style={{ alignSelf: 'center', flexShrink: 1 }} />

					<Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'none', lg: 'flex' }, columnGap: '8px', justifyContent: 'flex-end' }}>

						<TextButton onClick={() => navigateTo('#about')}>{t("menu.lotus-foil")}</TextButton>
						<TextButton onClick={() => navigateTo('#benefits')}>{t("menu.benefits")}</TextButton>
						<TextButton onClick={() => navigateTo('#news')}>{t("menu.news")}</TextButton>
						<TextButton onClick={() => navigateTo('#references')}>{t("menu.references")}</TextButton>
						<Divider />

						<SolidButton onClick={() => navigateTo('#contact')}>{t("menu.contact")}</SolidButton>
						{this.state.currentLanguage !== "en" &&
							<OutlineButton sx={langButtonStyle} onClick={(e) => this.handleLanguageChange(e, "en")}>{t("menu.en")}</OutlineButton>}
						{this.state.currentLanguage !== "cs" &&
							<OutlineButton sx={langButtonStyle} onClick={(e) => this.handleLanguageChange(e, "cs")}>{t("menu.cs")}</OutlineButton>}
					</Box>

					<Box sx={{ flexGrow: 1, display: { xs: 'flex',  md: 'flex', lg: 'none' }, justifyContent: 'flex-end' }}>
						<SolidButton color="primary" size="small" sx={{ p: '4px 8px', ml: '16px' }}
						aria-label="show more" aria-controls={mobileMenuId} aria-haspopup="true" 
							onClick={this.handleMobileMenuOpen}>
							<MenuIcon />
						</SolidButton>
					</Box>

				</Toolbar>}
			</Container>
		</StyledAppBar>
		
		<Box component="nav">
			<Drawer
				anchor="right"
				container={container}
				variant="temporary"
				open={Boolean(this.state.mobileMoreAnchorElement)}
				onClose={this.handleMobileMenuClose}
				ModalProps={{keepMounted: true}} sx={{
					display: { md: 'block', lg: 'none' },
					'& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
				}}>

					<Box onClick={this.handleMobileMenuClose} sx={{ bgcolor: 'primary.main', height: '100%' }}>
						<LotusLogoLight style={{ padding: '16px', alignSelf: 'center', maxWidth: '100%', height: 'unset', flexShrink: 1 }} />

						<MenuItem className="menu-label">
							<TextButtonInv onClick={() => navigateTo('#about')}>{t("menu.lotus-foil")}</TextButtonInv>
						</MenuItem>
						<MenuItem className="menu-label">
							<TextButtonInv onClick={() => navigateTo('#benefits')}>{t("menu.benefits")}</TextButtonInv>
						</MenuItem>
						<MenuItem className="menu-label">
							<TextButtonInv onClick={() => navigateTo('#new')}>{t("menu.news")}</TextButtonInv>
						</MenuItem>
						<MenuItem className="menu-label">
							<TextButtonInv onClick={() => navigateTo('#references')}>{t("menu.references")}</TextButtonInv>
						</MenuItem>
						<MenuItem className="menu-label">
							<TextButtonInv onClick={() => navigateTo('#contact')}>{t("menu.contact")}</TextButtonInv>
						</MenuItem>

						{this.state.currentLanguage !== "en" &&
							<MenuItem className="menu-label" onClick={(e)=>{  this.handleLanguageChange(e, "en"); }}>
								<OutlineButtonInv size='small'>{t("menu.en")}</OutlineButtonInv>
							</MenuItem>}
						{this.state.currentLanguage !== "cs" &&
							<MenuItem className="menu-label" onClick={(e)=>{  this.handleLanguageChange(e, "cs"); }}>
								<OutlineButtonInv size='small'>{t("menu.cs")}</OutlineButtonInv>
							</MenuItem>}
					</Box>

			</Drawer>
		</Box>

		<Container disableGutters maxWidth='xl' sx={{ display: 'flex', flexDirection: 'column'}}>
			<div id='intro'><Intro/></div>
			<Motto />
			<div id='about'><About /></div>
			<div id='function'><Function /></div>
			<div id='usage'><Usage /></div>
			<div id='benefits'><Benefits /></div>
			<div id='references'><Testers /></div>
			<Press />
			<div id='contact'><Contacts /></div>
			<FAQ />
			<Footer />
		</Container>
		
	</Box></BrowserRouter>)}</CustomThemeContext.Consumer>)
	}
}

export default withTranslation()(Base);