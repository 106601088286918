import { Typography } from '@mui/material';
import React from 'react'
import { styled } from '@mui/material/styles'

import {ReactComponent as Slashes} from '../assets/slashes.svg';
import { SxProps } from '@mui/system';

interface IState {
}

interface IProps {
    children?: React.ReactNode,
    variant: 'h1' | 'h2' | 'h3',
    lightBg: boolean,
    showIcon: boolean,
    offsetIcon: boolean,
    sx?: SxProps,
}

class Heading extends React.Component<IProps, IState> {

    static defaultProps: IProps = { variant: 'h1', lightBg: true, showIcon: true, offsetIcon: false };

	constructor(props: IProps){		
		super(props);				
		this.state = {};
	}

	render() {
        const fgColor = this.props.lightBg ? 'primary.main' : 'primary.contrastText';

        const Icon = styled(Slashes)(({ theme }) => ({ fill:
            this.props.lightBg ? theme.palette.primary.main : theme.palette.primary.contrastText,
            marginRight: '16px',
            position: 'relative',
            top: this.props.offsetIcon ? '-0.3em' : 0
        }));

        return <Typography sx={this.props.sx} variant={this.props.variant} color={fgColor}>
            {this.props.showIcon && <Icon />}
            {this.props.children}
        </Typography>;
	}
}

export default Heading;