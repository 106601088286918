import { Box } from '@mui/material';
import { SxProps, Theme } from '@mui/system';
import React from 'react'

interface IState {
}

interface IProps {
    children?: React.ReactNode,
    color: string,
    rounded: boolean,
    fullHeight: boolean,
    sx?: SxProps<Theme>
}

class Block extends React.Component<IProps, IState> {

    static defaultProps: IProps = { color: 'transparent', rounded: false, fullHeight: true }

	constructor(props: IProps){		
		super(props);				
		this.state = {};

	}

	render() {
		return <Box sx={{ 
            backgroundColor: this.props.color,
            borderRadius: this.props.rounded ? '41px' : 0,
            p: { xs: '30px', sm: '30px', md: '62px' },
            minHeight: this.props.fullHeight ? 'min(100vh, 1080px)' : 'auto',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            ...this.props.sx
        }}>

            {this.props.children}
        </Box>;
	}
}

export default Block;