import React from 'react'
import { Accordion as MuiAccordion, Box, BoxProps } from '@mui/material';
import { SxProps } from '@mui/system';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import AccordionDetails from '@mui/material/AccordionDetails';
import Switch from '@mui/material/Switch';
import { Trans } from 'react-i18next';

interface IState {
}

interface IProps {
    items: any[],
    summaryKey: string,
    detailsKey: string,
    lightBg: boolean,
    sx?: SxProps,
    boxProps?: BoxProps,
}

class Accordion extends React.Component<IProps, IState> {

    static defaultProps: Partial<IProps> = { lightBg: true, summaryKey: 'summary', detailsKey: 'details' };

	constructor(props: IProps){		
		super(props);				
		this.state = {};
	}

	render() {
        const fgColor = this.props.lightBg ? 'primary.main' : 'primary.contrastText';

		return <Box sx={this.props.sx} {...this.props.boxProps}>
                {this.props.items.map((item, index) =>
                    <MuiAccordion sx={{ color: fgColor }} key={index} square>
                        <AccordionSummary>
                            <Typography variant='body2'>{item[this.props.summaryKey]}</Typography>
                            <Switch edge='end' sx={{ color: fgColor }} />
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant='body3'>
                                <Trans>
                                    {item[this.props.detailsKey]}
                                </Trans>
                            </Typography>
                        </AccordionDetails>
                    </MuiAccordion>
                )}
            </Box>;
	}
}

export default Accordion;