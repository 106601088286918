import React from 'react'

import { Grid, Card as MuiCard, CardContent, CardMedia, CardProps } from '@mui/material';
import Typography from '@mui/material/Typography';
import Chip from './Chip';
import { SxProps } from '@mui/system';
import { Trans } from 'react-i18next';

interface ICardItem {
    title: string,
    description: string,
    tag: string,
    icon: React.ReactElement
}

interface IState {
}

interface IProps {
    item: ICardItem,
    lightBg: boolean,
    sx?: SxProps,
    cardProps?: CardProps,
}

class Card extends React.Component<IProps, IState> {

    static defaultProps: Partial<IProps> = { lightBg: true };
    
	constructor(props: IProps){		
        super(props);				
		this.state = {};
	}
    
	render() {
        const containerSx = { width: '100%', height: '100%', display: 'flex', justifyContent: 'space-between'};
		
        return <MuiCard {...this.props.cardProps} sx={{
            bgcolor: 'primary.main',
            p: { xs: '20px', sm: '20px', md: '30px'},
            width: { xs: '300px', sm: '330px', md: '530px' },
            height: { xs: '500px', sm: '400px', md: '300px'},
            ...this.props.sx }}>

                <Grid container sx={containerSx}>

                    <Grid item xs={12} sm={12} md={9}>
                        <CardContent sx={{ ...containerSx, flexDirection: 'column' }}>
                            <Typography variant='h2' color='primary.contrastText'>
                                {this.props.item.title}
                            </Typography>
                            <Typography variant='body2' color='primary.contrastText'>
                                <Trans>{this.props.item.description}</Trans>
                            </Typography>
                        </CardContent>
                    </Grid>

                    <Grid item xs={12} sm={12} md>
                        <CardMedia sx={{ ...containerSx, flexDirection: { xs: 'row-reverse', sm: 'row-reverse', md: 'column'}, alignItems: 'flex-end' }}>
                            {this.props.item.icon}
                            <Chip label={this.props.item.tag} />
                        </CardMedia>
                    </Grid>
                </Grid>
            </MuiCard>;
	}
}

export default Card;
export type { ICardItem };