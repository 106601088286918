import React from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { SolidButton, TextButton } from '../components/Button';
import Block from '../components/Block';
import Heading from '../components/Heading';
import Description from '../components/Description';
import { Box } from '@mui/material';

import introRaster from '../assets/intro.png';
import { ReactComponent as IntroVector } from '../assets/intro_shapes.svg';
import { navigateTo } from '../system/Util';

interface IState {
}

interface IProps extends WithTranslation {
}

class Intro extends React.Component<IProps, IState, WithTranslation> {

	constructor(props: IProps){		
		super(props);				
		this.state = {};
	}

	render() {	
		const t = this.props.t;
        const ns = 'main';
        const bgSx = { maxHeight: '709px', maxWidth: '80%', position: 'absolute', bottom: 0, right: 0, zIndex: -1 } as React.CSSProperties;

		return <Block sx={{ position: 'relative', justifyContent: { xs: 'flex-start', md: 'center' } }}>

            <Heading offsetIcon sx={{ width: { md: '70%' }, mt: '64px' }}>
                {t("title", {ns})}
            </Heading>
            
            <Description sx={{ width: { md: '50%' }, mt: '42px' }}>
                {t("pitch", {ns})}
            </Description>

            <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' },  columnGap: '16px' }}>
                <SolidButton onClick={() => navigateTo('#function')}>{t("what-lotus-foil-does", {ns})}</SolidButton>
                <TextButton onClick={() => navigateTo('#usage')}>{t("where-lotus-can-be-used", {ns})}</TextButton>
            </Box>

            <Box sx={{ width: '100%', height: 'calc(100% + 60px)', position: 'absolute', top: 0, left: 0, zIndex: -1, bgcolor: 'background.paper' }} />
            
            <IntroVector style={{ ...bgSx, width: 'unset', height: 'unset' }} />
            <img src={introRaster} alt='' style={bgSx} />
        </Block>;
	}
}

export default withTranslation()(Intro);