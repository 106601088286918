// Import basic packages:
import './app.css';
import './form.css';

// Import translation package:
import i18n from "i18next";
import backend from "i18next-http-backend";
import { useTranslation, initReactI18next } from "react-i18next";

// Import system components:
import Base from './system/Base';
import { SnackbarProvider } from 'notistack';
import { SnackbarUtilsConfigurator } from "./system/SnackBarUtils"

// Import fonts:
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

// Import modules:
import modules from './modules/modules'

// Make Axios better:
declare module 'axios' {
  export interface AxiosRequestConfig {
    setToken: any;
  }
}

// ======================================================================================================

// Setup translations:
var langArr = ['common'];
for (var i = 0; i < modules.length; i++)
{
	try {
	// @ts-ignore
	var l = modules[i].getLocale();
	if (l)
		langArr.push(l);
	} catch {}
}
i18n
	.use(backend)
	.use(initReactI18next) // passes i18n down to react-i18next
	.init({
		ns: langArr,
		lng: getLanguage(),
		fallbackLng: "en",
		interpolation: {
			escapeValue: false
		},
		react: {
			transSupportBasicHtmlNodes: true,
			transKeepBasicHtmlNodesFor: ['ul', 'ol', 'li', 'em', 'strong'],
		}
	});

// ======================================================================================================
export function save(key: string, value : string | null, toSession = false) : void {
	var obj = toSession? sessionStorage : localStorage;
	if (!value)
		obj.removeItem('lf_' + key);
	else
		obj.setItem('lf_' + key, value);
}

export function load(key: string, fromSession = false) : string | null {
	var obj = fromSession? sessionStorage : localStorage;
	const val = obj.getItem('lf_' + key);
	return val || null;
}

function setLanguage(langCode: string) : void {
	i18n.changeLanguage(langCode);
	save('language', langCode);
}

function getBrowserLanguage()
{
	let lang = navigator.language; //|| navigator.userLanguage;
	// Change cs-CZ to cs:
	lang = (lang.split('-')[0]).toLowerCase();
	return lang;
}

function getLanguage() : string {

	
	return load('language') || getBrowserLanguage() || 'en';
}

export function setPageTitle(s: string)
{
	const t = "Lotus Foil";
	if (s)
		document.title = s + " | " + t;
	else
		document.title = t;
}

	
// ======================================================================================================
function App() {

	// Setup language:
	const [t] = useTranslation(langArr);
	const lang = getLanguage();


	let modulesSorted = [ ...modules].sort((x : any, y : any) => { return x.menu().weight < y.menu().weight ? -1 : 1 });
	
	return (	
		<SnackbarProvider maxSnack={5}>
			<SnackbarUtilsConfigurator />
			
			<div className="wrapper">
				<Base modules={modulesSorted} setLanguage={setLanguage} currentLanguage={lang} />
			</div>
			
		</SnackbarProvider>);
}

export default App;
