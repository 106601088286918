import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import CustomThemeProvider from './system/CustomThemeProvider'
import BrandingProvider from './system/BrandingProvider'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
	<BrandingProvider>
	<CustomThemeProvider>
		<App />
	</CustomThemeProvider>
	</BrandingProvider>
  </React.StrictMode>
);