const navigateTo = (selector: string) => {
	const section = document.querySelector(selector);
    if (!section) return;

	section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
    if (selector.startsWith('#'))
        setTimeout(() => window.location.hash = selector, 1100);
};

const getMailTo = () => {
    return "mailto:libor@fortesinteractive.com"
}

export { navigateTo, getMailTo }