import React from 'react'
import { Trans, withTranslation, WithTranslation } from 'react-i18next'
import Block from '../components/Block';
import Heading from '../components/Heading';
import Description from '../components/Description';
import Emphasis from '../components/Emphasis';

import tablet from '../assets/tablet.png';
import Grid from '@mui/material/Grid/Grid';

interface IState {
}

interface IProps extends WithTranslation {
}

class Intro extends React.Component<IProps, IState, WithTranslation> {

	constructor(props: IProps){		
		super(props);				
		this.state = {};
	}

	render() {	
		const t = this.props.t;
        const ns = 'main';

		return <Block color='primary.main' rounded>

            <Grid container spacing={6} className='full-height'>
                <Grid item md={8} sx={{}}>
                    <Description lightBg={false} sx={{ m: 0 }}>
                        {t('motto', {ns})}
                    </Description>

                    <Heading lightBg={false} showIcon={false}>
                        <Trans i18nKey='safe-touch' ns={ns} components={[ <Emphasis lightBg={false} /> ]} />
                    </Heading>
                </Grid>

                <Grid item md={4} className='illustration'>
                    <img src={tablet} alt='' />
                </Grid>
            </Grid>
        </Block>;
	}
}

export default withTranslation()(Intro);