import React, { useState, useContext } from 'react'
import { Theme, ThemeProvider, createTheme, Shadows } from '@mui/material/styles';
import { BrandingContext }  from './BrandingProvider';


export interface ICustomThemeContext {
    currentTheme: {
      mode: string,
      branding: any // TODO
    },
    setTheme: (x: ICustomThemeContext['currentTheme']) => void
};

// eslint-disable-next-line no-unused-vars
export const CustomThemeContext = React.createContext<ICustomThemeContext>(
  {
    currentTheme: { mode: 'light', branding: { } },
    setTheme: (x: { mode: string, branding: any }) => { }
  },
)

const CustomThemeProvider = (props : any) => {


const defaultTheme: Theme = createTheme({});

const themeLight = createTheme({
  palette: {
    primary: {
      main: '#004AAD',
      contrastText: '#fff'
    },
    secondary: {
      main: '#00CEFF',
      contrastText: '#fff'
    },
    background: {
      paper: '#fff',
      default: '#F4F4F4',
    }
  },
  shadows: Array(25).fill('none') as Shadows,

  // breakpoints: {
  //   values: {
  //     xs: defaultTheme.breakpoints.values.xs,
  //     sm: defaultTheme.breakpoints.values.sm,
  //     md: defaultTheme.breakpoints.values.md,
  //     lg: defaultTheme.breakpoints.values.lg,
  //     xl: 1920,
  //   },
  // },

  typography: {
    fontFamily: [
      'Poppins',
      'sans-serif'
    ].join(','),

    h1: {
      fontWeight: 600,
      lineHeight: '135%',

      [defaultTheme.breakpoints.down('md')]: {
        fontSize: '42px',
        wordBreak: 'break-word',
      },
      [defaultTheme.breakpoints.up('md')]: {
        fontSize: '70px',
      },
    },
    h2: {
      fontWeight: 600,
      lineHeight: '130%',

      [defaultTheme.breakpoints.down('md')]: {
        fontSize: '26px',
        wordBreak: 'break-word',
      },
      [defaultTheme.breakpoints.up('md')]: {
        fontSize: '28px',
      },
    },
    body1: {
      fontWeight: 400,
      lineHeight: '170%',

      [defaultTheme.breakpoints.down('md')]: {
        fontSize: '18px',
      },
      [defaultTheme.breakpoints.up('md')]: {
        fontSize: '26px',
      },
    },
    body2: {
      lineHeight: '180%',

      [defaultTheme.breakpoints.down('md')]: {
        fontSize: '16px',
      },
      [defaultTheme.breakpoints.up('md')]: {
        fontSize: '18px',
      },
    },
    body3: {
      fontSize: '14px',
      lineHeight: '180%',
    },
    body4: {
      fontSize: '12px',
      lineHeight: '180%',
    }
  },
  
  components: {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          'body3': 'div',
          'body4': 'div',
        },
      },
    },

    MuiButtonBase: {
      defaultProps: {
        disableRipple: true
      }
    },

    MuiList: {
      defaultProps: {
        disablePadding: true,
        dense: true,
      }
    },

    MuiListItemButton: {
      defaultProps: {
        disabled: true,
      }
    },

    MuiListItemText: {
      defaultProps: {
        primaryTypographyProps: {
          variant: 'body1',
        }
      },
    },

    MuiSwitch: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          padding: '9px',
          
          '& .MuiSwitch-thumb': {
            color: 'currentColor',
          },
        },
        track: {
          height: '20px',
          borderRadius: '10px',
          border: '1px solid currentColor',
          opacity: 1,
          background: 'transparent',
        },
        switchBase: {
          color: 'unset',

          '&:hover, &.Mui-checked:hover': {
            backgroundColor: 'transparent',
          },

          '&.Mui-checked + .MuiSwitch-track': {
            border: '1px solid currentColor',
            background: 'transparent',
            opacity: 1,
          },

          '&.Mui-checked .MuiSwitch-thumb': {
            border: '1px solid currentColor',
          }
        },
      }
    },

    MuiAccordion: {
      styleOverrides: {
        root: {
          backgroundColor: 'transparent',

          '&:before': {
            opacity: 0,
          },
          '&:not(:first-of-type)': {
            borderTop: '1px solid currentColor'
          }
        }
      }
    },

    MuiAccordionSummary: {
      styleOverrides: {
        content: {
          justifyContent: 'space-between',
        }
      }
    },

    MuiChip: {
      defaultProps: {
        size: 'small' as 'small',
        variant: 'outlined' as 'outlined',
      },
      styleOverrides: {
        root: {
          fontSize: '12px',
          lineHeight: '22px',
          display: 'flex',
          flexGrow: '0 1',
        },
        outlined: {
          borderColor: 'currentColor',
        }
      }
    },

    MuiLink: {
      defaultProps: {
        underline: 'hover' as 'hover',
      },
      styleOverrides: {
        root: {
          display: 'block',
        }
      }
    },

    MuiCard: {
      styleOverrides: {
        root: {
          padding: 0,
          borderRadius: '20px',
        }
      }
    },

    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: 0,
        }
      }
    }
  },
});


  // eslint-disable-next-line react/prop-types
  const { children } = props

  // Read current theme from localStorage or maybe from an api
  const { currentBranding, setBranding } = useContext(BrandingContext);
  const currentTheme = { 'mode' : localStorage.getItem('lf_theme') || 'light', 'branding' : currentBranding };

  // State to hold the selected theme name
  const [themeData, _setThemeData] = useState(currentTheme)

  // Wrap _setThemeData to store new theme names in localStorage
  const setThemeData = (data : { mode : string, branding : any }) => {
    localStorage.setItem('lf_theme', data.mode);
	// We need a new object othervise react won't re-render:
	var newData = { 'mode' : data.mode, 'branding' : data.branding };
    _setThemeData(newData);
  }  

  const contextValue = {
    currentTheme: themeData,
    setTheme: setThemeData
  }
  
  var theme = themeLight;

  // Apply custom branding:
  if (currentTheme.mode === "light") {
	if (currentBranding.hasOwnProperty('colors'))
	  if (currentBranding.colors.hasOwnProperty('theme-light'))
		Object.assign(theme, currentBranding.colors['theme-light']);
  } else {
	if (currentBranding.hasOwnProperty('colors'))
	  if (currentBranding.colors.hasOwnProperty('theme-dark'))
		Object.assign(theme, currentBranding.colors['theme-dark']);
  }
  
  var themeObj = createTheme(theme);

  return (  
    <CustomThemeContext.Provider value={contextValue}>
	  <div className={"theme theme-" + currentTheme.mode}>
		<ThemeProvider theme={themeObj}>{children}</ThemeProvider>
	  </div>
    </CustomThemeContext.Provider>
  )
}



declare module '@mui/material/styles' {
  interface TypographyVariants {
    'body3': React.CSSProperties;
    'body4': React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    'body3'?: React.CSSProperties;
    'body4'?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    'body3': true;
    'body4': true;
  }
}

export default CustomThemeProvider
