import React from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import Block from '../components/Block';
import Heading from '../components/Heading';
import Description from '../components/Description';
import Grid from '@mui/material/Grid/Grid';

import { ReactComponent as TesterIcons } from '../assets/testers.svg';

interface IState {
}

interface IProps extends WithTranslation {
}

class Testers extends React.Component<IProps, IState, WithTranslation> {

	constructor(props: IProps){		
		super(props);				
		this.state = {};
	}

	render() {	
		const t = this.props.t;
        const ns = 'main';

		return <Block color='background.default'>

            <Grid container spacing={6} className='full-height'>
                <Grid item md={7}>
                    <Heading>
                        {t('who-we-test-with', {ns})}
                    </Heading>

                    <Description>
                        {t('testing-info', {ns})}
                    </Description>
                </Grid>

                <Grid item md={5} className='illustration'>
                    <TesterIcons />
                </Grid>
            </Grid>
        </Block>;
	}
}

export default withTranslation()(Testers);